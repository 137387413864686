<template>
  <div class="contentBox boxStyle">
    <div class="formOut">
      <div class="dataForm">
        <el-form
          ref="form"
          :model="configForm"
          label-position="left"
          label-width="180px"
          :rules="configFormRule"
        >
          <el-form-item :label="$t('systemConfig.locale')">
            <el-input v-model="configForm.locale"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.timeZone')">
            <el-input v-model="configForm.timeZone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.charset')">
            <el-input v-model="configForm.charset"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.cdiFtpSwitch')">
            <el-switch
              v-model="configForm.cdiFtpSwitch"
              :active-value="true"
              :inactive-value="false"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
            >
            </el-switch>
          </el-form-item>
          <template v-if="configForm.cdiFtpSwitch">
            <el-form-item :label="$t('systemConfig.cdiFtpIp')">
              <el-input v-model="configForm.cdiFtpHost"></el-input>
            </el-form-item>
            <el-form-item :label="$t('systemConfig.cdiFtpPort')">
              <el-input v-model="configForm.cdiFtpPort"></el-input>
            </el-form-item>
          </template>

          <el-form-item :label="$t('systemConfig.ftpServerHost')">
            <el-input v-model="configForm.ftpServerHost"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.ftpServerPort')">
            <el-input v-model="configForm.ftpServerPort"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.sftpServerHost')">
            <el-input v-model="configForm.sftpServerHost"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.sftpServerPort')">
            <el-input v-model="configForm.sftpServerPort"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.maxNumPerPost')">
            <el-input v-model.number="configForm.maxNumPerPost"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.dataFileKeepingDays')">
            <el-input
              v-model.number="configForm.dataFileKeepingDays"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.logFileKeepingDays')">
            <el-input v-model.number="configForm.logFileKeepingDays"></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.backupFileKeepingDays')">
            <el-input
              v-model.number="configForm.backupFileKeepingDays"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('systemConfig.httpWaitTime')">
            <el-input v-model.number="configForm.timeout"></el-input>
          </el-form-item>
          <!-- <el-form-item :label="$t('systemConfig.configFileBackUp')">
            <el-switch
              v-model="configForm.configFileBackup"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
            >
            </el-switch>
          </el-form-item>
          <template v-if="this.configForm.configFileBackup">
            <el-form-item
              :label="$t('systemConfig.publicIp')"
              prop="publicNetworkIp"
            >
              <el-input v-model="configForm.publicNetworkIp"></el-input>
            </el-form-item>
            <el-form-item :label="$t('systemConfig.localIp')" prop="localIp">
              <el-input v-model="configForm.localIp"></el-input>
            </el-form-item>
          </template> -->
          <el-row>
            <el-col :span="2" :offset="11">
              <el-button class="blueBtn" @click="submitForm()">{{
                $t("button.confirm")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "systemConfig",
  data() {
    let ipRule = (rule, value, callback) => {
      let regex =
        /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
      if (regex.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("promptMessage.ipRule")));
      }
    };
    return {
      configFormRule: {
        locale: [],
        timeZone: [],
        charset: [],
        cdiFtpHost: [],
        cdiFtpPort: [],
        dataKeepTime: [],
        httpWaitTime: [],
        publicNetworkIp: [
          { required: true, message: " ", trigger: "blur" },
          { validator: ipRule, trigger: "blur" },
        ],
        localIp: [
          { required: true, message: " ", trigger: "blur" },
          { validator: ipRule, trigger: "blur" },
        ],
      },
      configForm: {
        locale: "zh-cn",
        timeZone: "Asia/Shanghai",
        charset: "UTF-8",
        cdiFtpSwitch: false,
        cdiFtpHost: "127.0.0.1",
        cdiFtpPort: "21021",
        maxNumPerPost: 1000,
        ftpServerHost: "127.0.0.1",
        ftpServerPort: 21022,
        sftpServerHost: "127.0.0.1",
        sftpServerPort: 21025,
        dataFileKeepingDays: 7,
        logFileKeepingDays: 7,
        backupFileKeepingDays: 7,
        configFileBackup: false,
        publicNetworkIp: "",
        timeout: "61",
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let submitInfo = {
            locale: this.configForm.locale,
            timeZone: this.configForm.timeZone,
            charset: this.configForm.charset,
            cdiFtpSwitch: this.configForm.cdiFtpSwitch,
            cdiFtpHost: this.configForm.cdiFtpHost,
            cdiFtpPort: this.configForm.cdiFtpPort,
            ftpServerHost: this.configForm.ftpServerHost,
            ftpServerPort: this.configForm.ftpServerPort,
            sftpServerHost: this.configForm.sftpServerHost,
            sftpServerPort: this.configForm.sftpServerPort,
            senderWorkerNum: this.configForm.senderWorkerNum,
            maxNumPerPost: this.configForm.maxNumPerPost,
            dataFileKeepingDays: this.configForm.dataFileKeepingDays,
            logFileKeepingDays: this.configForm.logFileKeepingDays,
            backupFileKeepingDays: this.configForm.backupFileKeepingDays,
            configFileBackup: this.configForm.configFileBackup,
            timeout: this.configForm.timeout,
            publicNetworkIp: this.configForm.publicNetworkIp,
            localIp: this.configForm.localIp,
          };
          if (!submitInfo.configFileBackup) {
            delete submitInfo.publicNetworkIp;
            delete submitInfo.localIp;
          }
          return new Promise((resolve, reject) => {
            this.$httpList
              .sysConfig(submitInfo)
              .then((res) => {
                if (res.resultCode == 1001) {
                  this.$message({
                    type: "success",
                    message: "配置成功",
                  });
                  resolve(res);
                }
              })
              .catch((err) => {
                reject(err);
              });
          });
        }
      });
    },
    getSysConfig() {
      return new Promise((resolve, reject) => {
        this.$httpList
          .getSysConfig()
          .then((res) => {
            if (res.resultCode == 1001) {
              this.configForm = res.data;
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  created() {
    this.getSysConfig();
  },
};
</script>
<style lang="scss" scoped>
.formOut {
  display: flex;
  // max-width: 100%
  height: 100%;
  max-height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  .dataForm {
    min-width: 48%;
    max-width: 48%;
  }
  .certificate {
    min-width: 50%;
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    // box-shadow: 1px 1px 5px #888888;
    // align-items: center;
    .certificateBox {
      // border: 1px solid black;
      border-radius: 10px;
      padding: 10px;
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 2px;
      box-shadow: 1px 1px 5px #888888;
    }
  }
}
// .operateBtnOut {
//   position: fixed;
//   bottom: 7%;
//   left: 50%;
// }
/deep/.el-form-item__label {
  float: left;
}
</style>
